/* Student profile score card data */

const scoreCard = [];

for (let i = 0; i < 3; i++) {
    scoreCard.push({
        id: i,
        testId: 'T12'+i,
        testName: 'Moonlight',
        type: 'Mock test',
        examDate: '25 Sep, 2020',
        noOfQuestion: 125,
        feedback: 'yes'
    })
}

const dataList = [];

for (let i = 0; i < 15; i++) {
    dataList.push({
        id: i,
        QId: 'Q12'+i,
        questionName: 'Sunlight',
        section: 'Writing',
        item: 'Summarize',
        partOf: 'Mock Test',
        testId: 'T112'+i,
        examDate: '23 Oct, 2019',
        score: 125,
        feedback: 'yes'
    })
}

const profile = {
    fullName: 'KAZI SHORAFAT UDDIN AYENA',
    joiningDate: '15 March, 2013',
    dateOfBirth: '23 October, 1990',
    contactNumber: '+880 1711 123 456',
    email: 'yourname@yourcompany.com',
    address: 'House No 9, Road No 9, Baitul Aman Housing Society, Dhaka, Bangladesh'
}

export default {
    scoreCard,
    profile,
    dataList
}