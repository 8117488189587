<template>
  <div>
    <div>
      <div class="md-layout md-gutter my-4">
        <div class="md-layout-item">
            <SelectComponent
                class="w-full"
                label="Choose the data list"
            />
        </div>
        <div class="md-layout-item">
          <SelectComponent
                class="w-full"
                label="Choose The Category"
            />
        </div>
        <div class="md-layout-item">
          <SelectComponent
                class="w-full"
                label="Choose The Sub-Category"
            />
        </div>
        <div class="md-layout-item">
          <SelectComponent
                class="w-full"
                label="Choose The Sub-Category"
            />
        </div>
      </div>
    </div>
    <md-divider></md-divider>

    <Table :items="dataList" hover-action>
      <template slot="row" slot-scope="{ item }" md-selectable="single">
        <md-table-cell md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
        <md-table-cell md-label="Q_ID" class="text-victoria">{{ item.QId }}</md-table-cell>
        <md-table-cell md-label="QUESTION NAME" class="text-victoria">{{ item.questionName }}</md-table-cell>
        <md-table-cell md-label="SECTION">{{ item.section }}</md-table-cell>
        <md-table-cell md-label="ITEM">{{ item.item }}</md-table-cell>
        <md-table-cell md-label="PART OF">{{ item.partOf }}</md-table-cell>
        <md-table-cell md-label="TEST ID">{{ item.testId }}</md-table-cell>
        <md-table-cell md-label="EXAM DATE">{{ item.examDate }}</md-table-cell>
        <md-table-cell md-label="SCORE">{{ item.score }}</md-table-cell>
        <md-table-cell md-label="FEEDBACK" class="text-green">{{ item.feedback }}</md-table-cell>
        <span class="action">
              <md-icon
                class="bg-default rounded-full p-1 text-gray-700 text-base mr-1"
              >visibility</md-icon>
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base ">edit</md-icon>
            </span>
      </template>
    </Table>
  </div>
</template>

<script>
import { SelectComponent, Table } from "@/components";
import data from "@/data/profile/data"

export default {
  components: {
    Table,
    SelectComponent,
  },
  data() {
    return {
      dataList: data.dataList
    }
  }
};
</script>

<style></style>
