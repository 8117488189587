<template>
  <div>
    <div class="flex justify-between">
      <div>
        <label
          class=" mb-1 opacity-75 block text-uppercase font-bold text-italic"
          >Choose The Data List</label
        >
        <SelectComponent :items="dataListItems" class="w-64" @input="onSlectedItem" />
      </div>
      <div>
        <md-button
          class="bg-victoria rounded mr-3 text-white md-dense m-0 text-uppercase "
        >
          Feedback
        </md-button>

        <md-button
          class="bg-green rounded text-white md-dense m-0 text-uppercase "
        >
          Score PDF
        </md-button>
      </div>
    </div>
    <div class="my-6 border-b pb-4 border-solid border-gray-400">
      <h3 class="text-uppercase font-bold text-italic">Personal Information</h3>
    </div>
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-15">
        <div class="outline-gray-400 p-2 rounded">
          <img class="rounded" src="/images/profile.png" alt="">
        </div>
      </div>
      <div class="md-layout-item md-layout md-gutter">
        <div class="md-layout-item md-size-60 mb-6">
          <InputFieldComponent
            label="Full Name"
            italic
            opacity-class="opacity-75"
            v-model="profile.fullName"
          />
        </div>
        
        <div class="md-layout-item md-size-40 mb-6">
          <InputFieldComponent
            label="Joing Date"
            italic
            opacity-class="opacity-75"
            v-model="profile.joiningDate"
          />
        </div>
        
        <div class="md-layout-item md-size-40 mb-6">
          <InputFieldComponent
            label="Date of birth"
            italic
            opacity-class="opacity-75"
            v-model="profile.dateOfBirth"
          />
        </div>
        <div class="md-layout-item md-size-40 mb-6">
          <InputFieldComponent
            label="Contact Number"
            italic
            opacity-class="opacity-75"
            v-model="profile.contactNumber"
          />
        </div>
        <div class="md-layout-item md-size-40 mb-6">
          <InputFieldComponent
            label="Email Address"
            italic
            opacity-class="opacity-75"
            v-model="profile.email"
          />
        </div>
        <div class="md-layout-item md-size-100 mb-6">
          <InputFieldComponent
            label="Address"
            italic
            opacity-class="opacity-75"
            v-model="profile.address"
          />
        </div>

      </div>
      
    </div>
    <Table :items="scoreCard">
      <template slot="row" slot-scope="{ item }" md-selectable="single">
        <md-table-cell md-label="SL" md-numeric class="font-semibold">{{ item.id }}</md-table-cell>
        <md-table-cell md-label="TEST ID" class="font-semibold">{{ item.testId }}</md-table-cell>
        <md-table-cell md-label="TEST NAME" class="text-victoria font-semibold">{{ item.testName }}</md-table-cell>
        <md-table-cell md-label="TYPE" class="font-semibold">{{ item.type }}</md-table-cell>
        <md-table-cell md-label="Exam Date" class="font-semibold">{{ item.examDate }}</md-table-cell>
        <md-table-cell md-label="NO OF QUESTION" class="font-semibold">{{ item.noOfQuestion }}</md-table-cell>
        <md-table-cell md-label="FEEDBACK" class="text-green font-semibold">{{ item.feedback }}</md-table-cell>
      </template>
    </Table>
  </div>
</template>

<script>
import { InputFieldComponent, SelectComponent, Table } from "@/components";
import data from "@/data/profile/data"
export default {
  components: {
    Table,
    SelectComponent,
    InputFieldComponent
  },
  data() {
    return {
      profile: data.profile,
      scoreCard: data.scoreCard,
      dataListItems: [
        {id: 1, name: 'Basic Information', routeName: 'student.portfolio.dataList'},
        {id: 2, name: 'Another Navigation', routeName: ''},
        {id: 3, name: 'Dummy Navigation', routeName: ''},
      ]
    }
  },
  methods: {
    onSlectedItem(id) {
      let item = this.dataListItems.find(item => item.id == id);
      let { routeName } = item;

      if(routeName) {
        this.$router.push({
          name: routeName
        });
      }
    }
  }
};
</script>
